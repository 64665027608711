import React, { useEffect, useState, FormEvent, ChangeEvent } from 'react';
import { 
    Box, 
    Card, 
    CardContent,
    TextField, 
    FormHelperText, 
    CardHeader, 
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import { IoReloadSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { placeFoodOrder, sendConfirmationEmail } from '../../api/firebaseApi';
import '../../styles/forms.scss';

// Define alternate screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
};

// Define form state types
type FormState = {
    Title: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Timestamp: null | string;
    Verified: boolean;
    SubmissionUuid: string;
    Choice: Object;
    Comments: string;
};

type UserData = {
    Email: string;
    FirstName: string;
    LastName: string;
}

type Props = { 
    alert: Function; 
    close: Function; 
    refresh: Function;
    userData: UserData;
    uuid: string;
    title: string;
    options: Object;
};

export function SubmitFoodOrderForm(props: Props) {
    // Set states
    const { alert, close, refresh, userData, uuid, title, options } = props;
    const [formState, setFormState] = useState<FormState>({
        Title: title,
        Email: userData.Email,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Timestamp: null,
        Verified: false,
        SubmissionUuid: uuidv4(),
        Choice: {},
        Comments: '',
    });
    const [choiceName, setChoiceName] = useState('');
    const [limitReached, setLimitReached] = useState<boolean>(false);
    const [maxAlerts, setMaxAlerts] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [screenSize, setScreenSize] = useState(screens);
    const charLimit = 75;
    
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    const alertHandler = (payload: object) => {
        alert(payload);
    };

    const runChecks = (): boolean => {
        let isValid = true;
        const payload:any = [];

        // Check choice
        for (const part in formState.Choice) {
            if (formState.Choice[part] === '') {
                payload.push({
                    id: uuidv4(),
                    type: 'error',
                    message: 'Please select an option.'
                });
                isValid = false
            }
        }

        // Check user data
        if (!formState.Email || !formState.FirstName || !formState.LastName) {
            payload.push({
                id: uuidv4(),
                type: 'error',
                message: `
                    Could not query the following info: 
                        ${!formState.Email ? '| Email |' : ''} 
                        ${!formState.FirstName ? '| First Name |' : ''} 
                        ${!formState.LastName ? '| Last Name |' : ''}
                    `
            });
            payload.push({
                id: uuidv4(),
                type: 'info',
                message: 'This could be a cookie tracking issue. Please ensure you have accepted the cookie agreement. Additionally, try logging out then back in again. If the issue persists, contact Alex Bailey via Discord.',
                noClose: true,
            });
            isValid = false
        }

        if (payload.length > 0) {
            alertHandler(payload);
        }
        
        return isValid;
    };

    // Form submission function
    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitting(true);
        
        const formPayload = { ...formState };
        formPayload.Timestamp = dayjs().format('MM/DD/YYYY h:mm A');
        formPayload.Verified = true;
        const isValid = runChecks();
        if (isValid) {
            const response = await placeFoodOrder(uuid, formPayload);
            if (response[0]) {
                const payload = [{
                    id: uuidv4(),
                    type: 'success',
                    message: 'Order placed successfully!',
                }];
                formPayload.SubmissionUuid = formState.SubmissionUuid;
                const confirmationSent = await sendConfirmationEmail('FoodOrder', formPayload, [uuid]);
                if (confirmationSent[0]) {
                    payload.push({
                        id: uuidv4(),
                        type: 'success',
                        message: `Confirmation email for: ${formPayload.Email} has been queued`,
                    })
                    alertHandler(payload);
                    refresh();
                    closeModalHandler();
                }
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: response[1],
                }
                alertHandler(payload);
            }
        }
        setSubmitting(false);
    };

    const closeModalHandler = () => {
        close();
    };

    // Set new UUID
    const newUUID = () => {
        const updatedFormState = { ...formState };
        updatedFormState.SubmissionUuid = uuidv4();
        setFormState(updatedFormState);
    };

    const updateFormControlComment = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
    ) => {
        const { value } = event.target;
        if (value.length > charLimit) {
            setLimitReached(true);
            const message = {
                id: uuidv4(),
                type: 'warn',
                message: `Character limit reached!`,
            };
            setMaxAlerts(maxAlerts + 1);
            if (maxAlerts < 1) {
                alertHandler(message);
            }
        } else {
            setLimitReached(false);
            setMaxAlerts(0)
        }
        const updatedFormState = { ...formState };
        updatedFormState.Comments = value;
        setFormState(updatedFormState);
    };

    const updateFormControlOption = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
    ) => {
        const { value } = event.target;
        const updatedFormState = { ...formState };
        setChoiceName(value);
        const choicePayload = options[value];
        updatedFormState.Choice = { [value]: choicePayload };
        setFormState(updatedFormState);
    };

    const optionList = () => {
        let content = <></>;
        let radioOption = <></>;
        let list = [<></>]
        for (const option in options) {
            content = (
                <div className="options">
                    <p>{option}</p>
                    <ul>
                        <li>Entree: <span>{options[option].Entree}</span></li>
                        <li>Side: <span>{options[option].Side}</span></li>
                        <li>Drink: <span>{options[option].Drink}</span></li>
                    </ul>
                </div>
            );
            radioOption = <FormControlLabel value={option} control={<Radio />} label={content} />;
            list.push(radioOption);
        }
        return list;
    };

    return (
        <>
            <div id="place-food-order-form">
                <Box sx={{ display: 'inline-block', width: '100%'}}>
                    <Card>
                        <form onSubmit={submitForm}>
                            <CardHeader title="Place Food Order" />
                            <h3>Form: {formState.Title}</h3>
                            <h5>{uuid}</h5>
                            <CardContent>
                                <div className="padded">
                                    <RadioGroup 
                                        name="radio-buttons-group" 
                                        onChange={updateFormControlOption}
                                        value={choiceName}
                                    >
                                        {optionList().map((element) => element)}
                                    </RadioGroup>
                                </div>

                                <div className="padded">
                                    <TextField 
                                        multiline
                                        rows={4}
                                        id="comments" 
                                        label="Comments"
                                        variant="outlined"
                                        type="text"
                                        onChange={updateFormControlComment}
                                        value={formState.Comments}
                                    />
                                    <FormHelperText>
                                        <span className={limitReached ? 'form-error' : ''}>
                                            {`${formState.Comments.length} / ${charLimit} Characters`}
                                        </span>
                                    </FormHelperText>
                                    <FormHelperText style={{marginTop: -10}}>
                                        Add any special requests or food allergies here.
                                    </FormHelperText>
                                </div>

                                {/* Form UUID */}
                                <div className="padded">
                                    {(screenSize.small || screenSize.tablet) && (
                                        <FormHelperText>
                                            Order ID:
                                        </FormHelperText> 
                                    )}
                                    <FormHelperText>
                                        {(!screenSize.small && !screenSize.tablet) && <>Order ID:&nbsp;</>}
                                        <span className="font-bg1">{formState.SubmissionUuid}</span>&nbsp;
                                        <IoReloadSharp className="uuid-reload" onClick={newUUID} />
                                    </FormHelperText> 
                                </div>    
                            </CardContent>
                        </form>
                    </Card>
                </Box>
            </div>
            <br />
            <button 
                className="main-button modal" 
                disabled={submitting}
                onClick={(e) => submitForm(e)}
                style={{width: 'fit-content'}}
            >
                {submitting ? 'Placing...' : 'Place Order'}
            </button>
            <button className="outline-button modal" onClick={closeModalHandler}>Cancel</button>
        </>
    );
};
